import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogDandruff() {
	
	return (
		<Article
			title='My experience with curing dandruff'
			takeaway_title='Measures to cure the dandruff'
			page_id='Blog - Dandruff'
			read_time='5 mins'
			author='Brij Bhushan'
			image1='/media.jpg'
			image1_description='We often learn ways to deal with dandruff from mainstream media only'
			image2='/factors_dandruff.jpg'
			image2_description='Factors that contribute to dandruff'
			toggle_article='Solution'
			toggle_url='/article/soln_dandruff'
			related_article='Solution - Dandruff'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						I had my first experience with dandruff while I was still in school. In those days, applying hair oil was considered the only solution to deal with it. Since then, every winter it used to be oiling, washing & regular brushing of shoulders in winters. Then in my early 20s I noticed various anti-dandruff shampoos, which promised dandruff-free hairs through their ad campaigns. Eventually, I started using a ZPT-based shampoo. The message from the shampoo company was that dandruff is the result of a fungal infection. Once you have this infection, it cannot be cured and it can only be managed through their shampoo. Usage instructions recommended to wash hairs every alternate day till the dandruff disappears. Once the dandruff goes away then use it once in a week as maintenance, or else the infection will come back.
					</p>
					<p className='article_para'>
						So, I used this ZPT-based shampoo for the next several years. However, my experience was somewhat different from their promise. I didn’t get snowfall kind of dandruff, rather I used to get small acne-like eruptions on my scalp. These acne used to eventually get covered with thick dandruff-like flakes but they never used to fall-off in an embarrassing way. These flakes used to stick tight on the scalp. This way, I continued to struggle with the dandruff for several years but my dandruff was not visible to others’ eyes. I often wonder whether this is what the shampoo promised, when they claimed to help in managing the dandruff. I often tried to explain to myself that it is not dandruff, these are just the crust over the acne on my scalp.
					</p>
					<p className='article_para'>
						Later in life, I realized that my hairs are growing thinner & dry. Then I started reading about the various ingredients in the shampoo & harmful effects of these chemicals. Soon I realized that the dandruff condition is triggered by a fungal growth but a healthy skin & a healthy body can easily protect itself from this fungus called Malassezia globosa.
					</p>
					<p className='article_para'>
						It was an eye opener when I came to know that the white flakes of dandruff are not the fungus, rather it is increased skin growth on the scalp, which is triggered by the fungal infection but caused by an improper immune response of the body. In a holistic view, it is normal to have this fungus on human skin. First area of concern is the dryness of the skin which provides this fungus cracks to go under the skin. Second area of concern is weak immunity of the body which fails to sufficiently react to this fungal infection. Third area of concern is excess oil secretion on the scalp which guides the weak immune response to increased skin growth on the infected area. This increased skin growth results in the dandruff.
					</p>
					<p className='article_para'>
						Therefore, just washing off the fungus with antifungal chemicals will not solve the dandruff problem for good. To address the problem, one has to address the cause of the dryness, fix the problem with immunity and stop the excess secretion of oil. So, my dandruff got cured only after I completely moved to a zero-sugar diet and replaced refined oils with cold-pressed organic oils, for cooking. Sugar Is one of the main causes for weak immunity & refined oils are one of the causes of excess oil secretion. To know details of all the 6 measures to be taken to cure the dandruff, refer to the article on solution to the dandruff  <Link to='/article/soln_dandruff'>here</Link>)
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: 'Wash your scalp regularly',
	},
	{
		takeaway: 'Give your hair & scalp exposure to sunlight for atleast 5 mins a day',
	},
	{
		takeaway: 'Once in a week apply oil on hairs',
	},
	{
		takeaway: 'Remove sugar & sugar-based products from diet',
	},
	{
		takeaway: 'Add fresh fruits & raw vegetables in the diet',
	},
	{
		takeaway: 'Stop cooking food with refined oils',
	},
];

const RelatedArticles = [
	{
		title: 'Solution - Dandruff',
		path: '/article/soln_dandruff',
	},
];



